import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import find from 'lodash/find';

import routes from 'routes/routes';
import styles from './Header.module.scss';

const Header = (props) => {
  const windowLocation = useLocation();

  useEffect(() => {
    const matchedRoute = find(routes, (r) => {
      return r.path === windowLocation.pathname;
    });
    document.title = matchedRoute.title;
  }, [windowLocation]);

  return (
    <React.Fragment>
      <div className={`${styles.jcHeader} mb-4`}>
        <div className="fancy-site-title">
          <h3>JoshCaplin.dev</h3>
        </div>
        <Navbar collapseOnSelect expand="lg" className={`${styles.jcHeaderNavbar} navbar-dark`}>
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/home" href="/home">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/demos" href="/demos">
                  DEMOS
                </Nav.Link>
                <Nav.Link as={Link} to="/blog" href="/blog">
                  Blog
                </Nav.Link>
                <Nav.Link as={Link} to="/consulting" href="/consulting">
                  Hire Me
                </Nav.Link>
                <Nav.Link as={Link} to="/3dprinting" href="/3dprinting">
                  3D Printing
                </Nav.Link>
                <Nav.Link as={Link} to="/feedback" href="/feedback">
                  Feedback
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link
                  href="https://www.linkedin.com/in/joshcaplin"
                  target="_blank"
                  className="d-none d-lg-inline"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedin} className={styles.jcSocialIcon} />
                </Nav.Link>
                <Nav.Link
                  href="https://twitter.com/JoshCaplin/"
                  target="_blank"
                  className="d-none d-lg-inline mx-3"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} className={styles.jcSocialIcon} />
                </Nav.Link>
                <Nav.Link
                  href="https://github.com/joshcaplin"
                  target="_blank"
                  className="d-none d-lg-inline"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faGithub} className={styles.jcSocialIcon} />
                </Nav.Link>

                <Navbar.Text className="d-lg-none mt-2">
                  <div className="text-center">
                    <a
                      href="https://www.linkedin.com/in/joshcaplin"
                      target="_blank"
                      className="me-5"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} className={styles.jcSocialIcon} />
                    </a>
                    <a
                      href="https://twitter.com/JoshCaplin/"
                      target="_blank"
                      className="me-5"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} className={styles.jcSocialIcon} />
                    </a>
                    <a href="https://github.com/joshcaplin" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faGithub} className={styles.jcSocialIcon} />
                    </a>
                  </div>
                </Navbar.Text>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </React.Fragment>
  );
};

export default Header;
