import axios from 'axios';

const getExpressApiWarmup = async () => {
  const url = `https://joshcaplindev--express.azurewebsites.net/warmup`;

  return axios
    .get(url)
    .then((response) => {
      return {
        msg: response.data,
        isError: false,
      };
    })
    .catch(() => {
      return {
        type: 'error',
        isError: true,
      };
    });
};

export default getExpressApiWarmup;
