import React, { createContext, useContext, useEffect, useState } from 'react';

export const useAppContext = () => useContext(AppContext);
const AppContext = createContext();

const AppProvider = (props) => {
  const [currentAppVersion, setCurrentAppVersion] = useState(1.2345);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileSingleColGrid, setIsMobileSingleColGrid] = useState(false);
  const [jokeCategory, setJokeCategory] = useState('Nerdy');

  //TODO: useEffect & checkIfMobile should be moved to App component, not kept here
  // useEffect(() => {
  //   checkIfMobile();
  //   window.addEventListener('resize', checkIfMobile);
  //   return () => window.removeEventListener('resize', checkIfMobile);
  // }, []);

  // const checkIfMobile = () => {
  //   const maxMobileWidth = 991;
  //   const maxSingleColGridWidth = 767;

  //   const isAtMobileWidth = window.innerWidth <= maxMobileWidth;
  //   const isAtSingleColGridWidth = window.innerWidth <= maxSingleColGridWidth;

  //   setIsMobile(isAtMobileWidth);
  //   setIsMobileSingleColGrid(isAtSingleColGridWidth);
  // };
  //END TODO **********************************************************************

  const updateCurrentAppVersion = (newVersion) => {
    setCurrentAppVersion(999);
  };

  const updateJokeCategoryToFetch = (userSelection) => {
    setJokeCategory(userSelection);
  };

  return (
    <AppContext.Provider
      value={{
        currentAppVersion,
        isMobile,
        setIsMobile,
        isMobileSingleColGrid,
        setIsMobileSingleColGrid,
        jokeCategory,
        updateCurrentAppVersion,
        updateJokeCategoryToFetch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
