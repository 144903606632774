import React, { Suspense, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import {
  //BrowserRouter as Router,
  Router as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
//import ReactGA from "react-ga";
import routes from 'routes/routes';

import { AppProvider } from 'contexts/AppContext';
import getExpressApiWarmup from 'services/getExpressApiWarmup';
import { gaInitialize, gaPageView } from 'utilities/googleAnalyticsHelper';

import Header from 'components/Shared/Header/Header';
import Footer from 'components/Shared/Footer/Footer';
import LoadingGears from 'components/Shared/LoadingGears/LoadingGears';

import 'styles/main.scss';
import styles from './App.module.scss';

const App = () => {
  useEffect(() => {
    gaInitialize();
    gaPageView();
    // Express API takes a while to start up, so calling warmup asap to minimize this
    getExpressApiWarmup();
  }, []);

  const history = createBrowserHistory();
  history.listen((location, action) => {
    gaPageView();
    //console.log(`ga logging page: ${location.pathname}`);
  });

  //TODO: adjust setup so able to read/write to app context here - setting isMobile, etc

  const renderLoader = () => (
    <div className="my-5">
      <LoadingGears />
    </div>
  );

  const routingComponents = (
    <Switch>
      {routes.map((route) => {
        const Component = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props) => <Component {...props} />}
          />
        );
      })}
    </Switch>
  );

  return (
    <React.Fragment>
      <AppProvider>
        <div className={styles.app}>
          <Container>
            <Router history={history}>
              <Header />
              <Suspense fallback={renderLoader()}>{routingComponents}</Suspense>
            </Router>
            <Footer />
          </Container>
        </div>
      </AppProvider>
    </React.Fragment>
  );
};

export default App;
