import React from 'react';
import Gears from 'assets/images/gears.gif';
import styles from './LoadingGears.module.scss';

const LoadingGears = (props) => {
  const { textLabel } = { ...props };
  const displayText = textLabel || 'Loading';
  return (
    <React.Fragment>
      <span className={styles.loadingGears}>
        {displayText}... <img height="50" width="50" src={Gears} alt="gears" />
      </span>
    </React.Fragment>
  );
};

export default LoadingGears;
