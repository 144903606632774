import { combineReducers } from 'redux';

import BreakingBadReducer from 'components/Demos/BreakingBadCharacters/redux/reducer';
//import DemosReducer from 'components/Demos/redux/reducer';

const rootReducer = combineReducers({
  breakingBad: BreakingBadReducer,
  //demos: DemosReducer,
});

export default rootReducer;
