import ReactGA from 'react-ga';

export const gaInitialize = () => {
  const trackingId = 'UA-136295055-2';
  ReactGA.initialize(trackingId);
};

export const gaPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const gaEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
