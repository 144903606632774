import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import styles from './Footer.module.scss';

const Footer = (props) => {
  return (
    <React.Fragment>
      <footer className={`${styles.footer} fancy-box my-5`}>
        <Row>
          <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className="text-center mb-1">
            <div className={styles.footerIconWrapper}>
              <a href="https://www.linkedin.com/in/joshcaplin" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className={styles.jcSocialIcon} />
              </a>
              <a href="https://twitter.com/JoshCaplin/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} className={styles.jcSocialIcon} />
              </a>
              <a href="https://github.com/joshcaplin" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} className={styles.jcSocialIcon} />
              </a>
              {/* Easter Egg!  Not a mistake, Rickrolling anyone who clicks on Facebook link  :)  */}
              <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} className={styles.jcSocialIcon} />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className="text-center">
            <span>
              <small>joshcaplin.dev</small>
              <small className="ms-3">vR-2.0.9</small>
            </span>
          </Col>
        </Row>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
