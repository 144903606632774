import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const Blockchain = lazy(() => import(/* webpackChunkName: 'Blockchain' */ 'components/Blockchain/Blockchain'));
const Blog = lazy(() => import(/* webpackChunkName: 'Blog' */ 'components/Blog/Blog'));
const Consulting = lazy(() => import(/* webpackChunkName: 'Consulting' */ 'components/Consulting/Consulting'));
const Demos = lazy(() => import(/* webpackChunkName: 'Demos' */ 'components/Demos/Demos'));
const Feedback = lazy(() => import(/* webpackChunkName: 'Feedback' */ 'components/Feedback/Feedback'));
const Home = lazy(() => import(/* webpackChunkName: 'Home' */ 'components/Home/Home'));
const Printing = lazy(() => import(/* webpackChunkName: 'Printing' */ 'components/Printing/Printing'));

const routes = [
  {
    path: '/',
    component: () => <Redirect to="home" />,
    exact: true,
  },
  {
    path: '#/demos',
    component: () => <Redirect to="demos" />,
    exact: true,
  },
  {
    title: 'JC - Blockchain',
    path: '/blockchain',
    component: Blockchain,
    exact: true,
  },
  {
    title: 'JC - Blog',
    path: '/blog',
    component: Blog,
    exact: true,
  },
  {
    title: 'JC - Consulting',
    path: '/consulting',
    component: Consulting,
    exact: true,
  },
  {
    title: 'JC - Demos',
    path: '/demos',
    component: Demos,
    exact: true,
  },
  {
    title: 'JC - Feedback',
    path: '/feedback',
    component: Feedback,
    exact: true,
  },
  {
    title: 'JoshCaplin.dev',
    path: '/home',
    component: Home,
    exact: true,
  },
  {
    title: 'JC - 3D Printing',
    path: '/3dprinting',
    component: Printing,
    exact: true,
  },
];

export default routes;
