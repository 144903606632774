import find from 'lodash/find';

import {
  BB_CHARACTERS_ADD,
  BB_FETCH_CHARACTER_SUCCESS,
  BB_FETCH_CHARACTER_STARTED,
  BB_FETCH_CHARACTER_FAILURE,
  BB_SET_ACTIVE_CHARACTER,
} from './types';

const initialState = {
  activeCharacterId: null,
  characters: [],
  hasError: false,
  isLoading: false,
};

const breakingBadReducer = (state = initialState, action) => {
  switch (action.type) {
    case BB_CHARACTERS_ADD:
      return {
        ...state,
        characters: [...state.characters, action.payload],
      };
    case BB_FETCH_CHARACTER_STARTED:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case BB_FETCH_CHARACTER_SUCCESS:
      return {
        ...state,
        activeCharacterId: action.payload.char.charId,
        characters: [...state.characters, action.payload.char],
        isLoading: false,
        hasError: false,
      };
    case BB_FETCH_CHARACTER_FAILURE:
      return {
        ...state,
        activeCharacterId: 0,
        isLoading: false,
        hasError: true,
      };
    case BB_SET_ACTIVE_CHARACTER:
      return {
        ...state,
        activeCharacterId: action.payload,
      };
    default:
      return state;
  }
};

export const selectCharacterById = (state, searchId) => {
  const char = find(state.breakingBad.characters, (c) => {
    return c.charId == searchId;
  });
  return char;
};
export const selectCurrentCharacter = (state) => {
  const { activeCharacterId } = state.breakingBad;
  const char = find(state.breakingBad.characters, (c) => {
    return c.charId == activeCharacterId;
  });
  return char;
};

export const selectIsLoading = (state) => {
  return state.breakingBad.isLoading;
};

export default breakingBadReducer;
